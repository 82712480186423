@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #97df96 #edeef1;
  scrollbar-width: thin;
}
