/* custom-swiper.css */

/* Custom pagination styles */
.swiper-pagination-bullet {
  background-color: #60c860; /* Custom color */
  opacity: 1; /* Ensure visibility */
}

.swiper-pagination-bullet-active {
  background-color: #38a538; /* Active state color */
}

/* Custom navigation styles */
.swiper-button-next,
.swiper-button-prev {
  color: #32cd32; /* Custom color */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #38a538; /* Hover state color */
}
